import { store } from "../store/index"
import { mapState, mapActions, mapGetters } from "vuex"
import * as moment from "moment"

const initialState = () => ({
  right: false,
  wrong: false,
  finalwrong: false,
  received: false,
  theTime: "0",
  timesUp: false,
  timer: 0,
  test: 0,
  progressColor: "green darken-4",
  currentTry: 1,
  missionStatus: "active",
  teamPlayed: false,
  imageName: "",
  imageUrl: "",
  imageFile: "",
  loadingImage: false,
  print: false,
  hasImage: false,
  showPhotoModal: false,
  showPhoto: null,
  userPlayed: {},
  gifs: [],
  gifCounter: 1,
  gifSearch: null,
  answer: null,
  caption: null,
  multiAnswer: 0,
  gotItRight: false,
  submitted: false,
  checking: false
})

export const GameMixin = {
  data: () => initialState(),
  mounted() {
    // this.checkPlay()
    const { getCurrentMode, missionCompleted, isHost, isVoting } = this
    const isPlay = getCurrentMode == "play"
    const isHuddle = getCurrentMode == "huddle"
    if (missionCompleted && (isPlay || isHuddle) && !isHost && isVoting) {
      this.updateVotingOverride(true)
    }
  },
  watch: {
    currentMission(prevState, curState) {
      // console.log(prevState, curState)
      // store.dispatch("setPlay", null)
      if (prevState && curState) {
        //console.log("WATCHING CURRENM MISSION")
        if (prevState.pos !== curState.pos) {
          // console.log('COMPARING is different')
          this.missionStatus = "active"
          this.answer = null
          this.multiAnswer = null
          this.hasImage = null
          this.imageFile = null
          this.caption = null
          this.currentTry = 1
          this.gotItRight = false
        }
      }
    },
    missionCompleted(newValue) {
      if (newValue && this.isPlayer && this.isVoting) {
        this.updateVotingOverride(true)
      }
    },
    anyTeamMissionCompletedPlays(array) {
      if (!Array.isArray(array)) return
      const num = array.length
      if (num && (this.isAudit || this.isSpectator) && this.isVoting) {
        this.updateVotingOverride(true)
      }
    }
  },
  computed: {
    ...mapState({
      gameStatus: state => state.mission.gameStatus,
      play: state => state.play.play,
      teams: state => state.chat.chats,
      typicalModes: state => state.typicalModes,
      assignedTeam: state => state.drawing.assignedTeam
    }),
    ...mapState("group", ["modes", "currentGlobalTeam"]),
    ...mapGetters("livechat", ["roomID"]),
    ...mapGetters("GameUsers", ["usersOnlineArray"]),
    ...mapGetters({ missionID: "currentMission" }),
    ...mapGetters([
      "plays",
      "user",
      "teamID",
      "game",
      "getCurrentMode",
      "missionPlays",
      "nOfMissionTries",
      "missionPlaysArray",
      "missionTeamPlaysArray",
      "missionUserPlaysArray",
      "missionCompleted",
      "missionCorrectAnswer",
      "missionSuccessfulPlays",
      "anyTeamMissionCompletedPlays",
      "onlineUsersGroupedByTeam",
      "nOfAllCorrectMissionPlays"
    ]),
    ...mapGetters("auth", ["isPlayer", "isAudit", "isHost"]),
    isVoting() {
      return this.missionModes.includes("voting")
    },
    team() {
      return this.teamID && this.teams ? this.teams[this.teamID] : null
    },
    currentTeamID() {
      return this.isHost ? this.currentGlobalTeam : this.teamID
    },
    teamUsers() {
      return this.onlineUsersGroupedByTeam[this.currentTeamID] || []
    },
    teamMission() {
      if (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Team: Speed Does Not Matter" ||
        this.currentMission.playType == undefined
      ) {
        return true
      }
      return false
    },
    speedMatters() {
      if (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Individual: Speed Matters" ||
        this.currentMission.playType == undefined
      ) {
        return true
      }
      return false
    },
    currentMission() {
      return store.getters.getCurrentMission
    },
    missionModes() {
      var modes = Object.values(this.modes)
      var theModes = []
      for (var i in modes) {
        if (this.currentMission[modes[i]]) {
          theModes.push(modes[i])
        }
      }
      if (theModes.length == 0) {
        var typicalModes = this.typicalModes[this.currentMission.behavior]
        for (var j in typicalModes) {
          theModes.push(typicalModes[j])
        }
      }
      return theModes
    }
  },
  methods: {
    ...mapActions("group", ["updateVotingOverride"]),
    ...mapActions("soundeffect", ["updateTeamSoundEffect"]),
    sortedArray(arr, field) {
      return arr.sort((a, b) => b[field] - a[field])
    },
    userScore(userID) {
      if (!this.plays.length) return 0
      if (!userID) return 0
      const userPlays = this.plays.filter(item => item.userID === userID)
      return userPlays.reduce((acc, val) => acc + this.getInt(val.score), 0)
    },
    incorrectPlays() {
      let data = this.missionPlaysArray
      if (!data) return 0
      if (!data.length) return 0
      const teamID = this.currentTeamID
      let tries = 0
      if (this.teamMission) {
        data = data.filter(item => {
          // filter by correct and missionid
          return !item.result && item.teamID == teamID
        })
      } else {
        data = data.filter(item => {
          // filter by correct and missionid
          return !item.result && item.userID == this.user.id
        })
      }
    },
    correctPlays() {
      let data = this.missionPlaysArray
      if (!data) return 0
      if (!data.length) return 0
      const teamID = this.teamID
      if (this.teamMission) {
        data = data.filter(item => {
          // filter by correct answer
          return item.result && item.teamID == teamID
        })
      } else {
        data = data.filter(item => {
          // filter by correct answer
          return item.result && item.userID == this.user.id
        })
      }
      return data.length
    },
    correctMissionPlays() {
      let data = this.missionPlaysArray
      if (!data) return 0
      if (!data.length) return 0
      // filter by correct answer
      data = data.filter(item => item.result)
      return data.length
    },
    getInt(value) {
      const n = parseInt(value)
      return isNaN(n) ? 0 : n
    },
    computeScore({ isTimeLimited, nOfTeams, nOfCorrect, points }) {
      const pointsInt = this.getInt(points)
      if (isTimeLimited) {
        const nOfTeamsInt = this.getInt(nOfTeams)
        const nOfCorrectInt = this.getInt(nOfCorrect)
        // can't divide by zero
        if (nOfTeamsInt < 1) return 0
        const n = ((nOfTeamsInt - nOfCorrectInt) / nOfTeamsInt) * pointsInt
        return Math.round(n)
      } else {
        return pointsInt
      }
    },
    // the function must be refactored so that as computations happen, they fill
    // an array with promises and then resolve in bulk in the end of the function
    // that would speed submit time up and make it more predictable
    async checkAnswer(sentMissionID) {
      if (this.checking) return console.warn("still checking...")
      this.checking = true
      try {
        console.log("GAME ", this.game)
        var numTries = this.game.numOfTries || 10000
        if (numTries == "Unlmited") {
          numTries = 10000
        }
        if (this.currentMission.numOfTries) {
          numTries = this.currentMission.numOfTries
        }
        var teamname = null
        var teamID = null
        console.log("TEAM")
        console.log(this.team)
        if (!this.team) {
          teamname = "Host"
          teamID = 0
        } else {
          teamID = this.currentTeamID
          teamname = this.team.name
        }
        var sTime = new Date()
        var theTime = moment(sTime).unix()
        var realTime = moment(sTime).format("LLLL")
        var points = this.currentMission.points
        if (
          !this.teamMission &&
          !this.currentMission.behavior == "Categories"
        ) {
          points = points / this.teamUsers.length
        }
        var name = this.currentMission.name
        const behavior = this.currentMission.behavior
        var pollAns = this.multiAnswer
        var payload = {
          missionID: this.missionID,
          answer: [this.answer],
          userID: this.user.id,
          username: this.user.firstname,
          firstName: this.user.firstname,
          lastName: this.user.lastname,
          points: points,
          time: theTime,
          readTime: realTime,
          teamID: teamID,
          game: this.game.name,
          teamname: teamname,
          mission: name,
          behavior: behavior,
          show: true
        }
        if (this.game.clientID) payload.clientID = this.game.clientID
        if (sentMissionID) payload.missionID = sentMissionID

        // got it right
        if (this.currentMission.behavior == "Photo") {
          if (this.imageUrl) {
            this.gotItRight = true
            this.answer = { image: this.imageUrl, caption: this.caption }
          }
        }
        if (this.currentMission.behavior == "Take Photo") {
          if (this.imageUrl) {
            this.gotItRight = true
            this.answer = { image: this.imageUrl }
            // console.log("take photo user", this.user)
            // if (this.mission.useAsAvatar) {
            await this.$store.dispatch("updateUser", {
              userID: this.user.id,
              obj: { image: this.imageUrl }
            })
            // }
          }
        }
        if (
          this.currentMission.behavior == "Text" ||
          this.currentMission.behavior == "Draw: Pictionary"
        ) {
          var ansArr = this.currentMission.answer.split(",")
          for (var i in ansArr) {
            if (this.answer.toLowerCase() === ansArr[i].toLowerCase().trim()) {
              this.gotItRight = true
            }
          }
        }
        if (this.currentMission.behavior == "Categories") {
          for (var d in this.missionCorrectAnswer) {
            if (
              this.answer.toLowerCase() ===
              this.missionCorrectAnswer[d].toLowerCase().trim()
            ) {
              if (!this.isAnswerPlayed(this.answer.toLowerCase())) {
                this.gotItRight = true
                // await store.dispatch("setPlay", null)
              }
            }
          }
        }
        if (
          this.currentMission.behavior == "Freeform" ||
          this.currentMission.behavior == "Fact Match" ||
          this.currentMission.behavior == "Draw: Each Team"
        ) {
          if (this.answer) {
            this.gotItRight = true
            // if (this.isHost) payload.teamID = this.currentGlobalTeam
            // console.log("GlobalTeamID", this.currentGlobalTeam)
            // console.log(this.gotItRight)
          }
          if (this.imageUrl) {
            this.gotItRight = true
            this.answer = {
              image: this.imageUrl,
              caption: this.caption
            }
          }
        }
        if (this.currentMission.behavior == "Giphy") {
          if (this.answer && this.caption) {
            this.gotItRight = true
          }
        }
        if (this.currentMission.behavior == "URL") {
          if (this.answer) {
            if (this.answer.split("v=")[1]) this.gotItRight = true
          }
        }
        if (this.currentMission.behavior == "Team Name") {
          if (this.answer) {
            this.gotItRight = true
            //console.log("INSIDE TEAM NAME AND GOT IT RIGHT")
            //console.log(this.gotItRight)
            let obj = {}
            obj.id = this.currentTeamID
            obj.name = this.answer
            await store.dispatch("changeTeamName", obj)
          }
        }
        if (this.currentMission.behavior == "Multiple Choice") {
          if (this.multiAnswer + 1 == this.currentMission.multiCorrect) {
            this.gotItRight = true
          }
          this.answer = this.multipleChoice[this.multiAnswer]
        }
        if (this.currentMission.behavior == "Poll") {
          if (pollAns >= 0 && pollAns != null) {
            this.gotItRight = true
            console.log("POLL YES")
          }
          this.answer = this.multipleChoice[pollAns]
        }

        // add to the answer array on play
        if (this.play && this.play.answer) {
          console.log("THERE IS A PLAY ", this.play)
          payload.id = this.play.id
          if (this.play.answer) {
            var ans = Object.values(this.play.answer)
            ans.push(this.answer)
            payload.answer = ans
          }
        } else {
          payload.answer = [this.answer]
        }
        let score
        if (this.gotItRight) {
          var s = {}
          s.teamID = this.currentTeamID
          s.name = "GotItRight"

          this.updateTeamSoundEffect({ key: "GOT_IT_RIGHT", status: "play" })

          payload.result = true

          if (
            this.currentMission.behavior == "Photo" ||
            this.currentMission.behavior == "Poll" ||
            this.currentMission.behavior == "Freeform" ||
            this.currentMission.behavior == "Take Photo" ||
            this.currentMission.behavior == "Team Name" ||
            this.currentMission.behavior == "URL" ||
            this.currentMission.behavior == "Giphy"
          ) {
            this.received = true
          } else {
            this.right = true
          }

          if (this.currentMission.behavior == "Categories") {
            this.received = true
            this.right = true
          }

          const correct = this.correctMissionPlays()
          let numTeams = 0
          if (this.teams) {
            if (this.teamMission) {
              numTeams = Object.keys(this.teams).length
            } else {
              numTeams = this.usersOnlineArray.length
            }
          }

          score = this.computeScore({
            isTimeLimited: this.speedMatters,
            nOfCorrect: correct,
            nOfTeams: numTeams,
            points
          })

          if (score <= 0) score = 5

          console.log(score)

          payload.score = score

          if (this.isVoting) payload.score = 0
          this.missionStatus = "completed"
          console.log(payload)
          if (this.currentMission.behavior == "Categories") {
            this.missionStatus = "active"
          }
          if (
            this.currentMission.behavior == "URL" ||
            this.currentMission.behavior == "Giphy"
          ) {
            var obj = {}
            obj.image = this.answer
            if (this.caption) obj.caption = this.caption
            payload.correct = obj
          } else {
            payload.correct = this.answer
          }
          payload.completed = true
          // this.gotItRight = false
          this.answer = null
          // console.log("THE MISSION STATUS" + this.missionStatus)
        } else {
          var s = {}
          s.teamID = this.currentTeamID
          s.name = "GotItWrong"

          this.updateTeamSoundEffect({ key: "GOT_IT_WRONG", status: "play" })

          payload.result = false
          payload.score = 0
          //console.log("GOT IT WRONG")
          //console.log('wrong')
          payload.correct = this.answer // kinda a kludge to store the answer and show on submissions.
          this.currentTry++
          this.wrong = true
          //console.log("numoftries original = " + this.nOfMissionTries)
          //console.log(this.incorrectPlays >= this.nOfMissionTries - 1)
          if (
            this.incorrectPlays >= this.nOfMissionTries - 1 ||
            this.currentTry > this.numOfTriesActual ||
            this.nOfMissionTries == 1
          ) {
            // console.log("GOT IT WRONG STUFF")
            this.wrong = false
            this.finalwrong = true
            payload.completed = true
            this.missionStatus = "completed"
          }
        }
        // console.log("HERES THE BAD PLAY PAYLOAD")
        // console.log(payload)

        if (this.currentMission.behavior == "Categories" && this.gotItRight) {
          delete payload.id
          this.gotItRight = false // reset for next one
        }
        if (this.roomID) {
          payload.roomID = this.roomID
        }
        console.log("HERES THE PLAY")
        console.log(payload)

        // just give the one person the score cause this is individual
        // only let one submission for the drawing
        console.log("currentmission", this.currentMission)

        const updateTeamScore = async payload => {
          if (!this.teams) return console.error(`Teams are not available`)

          console.log("updateTeamScore", payload)

          var team = this.teams[payload.teamID]
          if (this.isHost) team = this.teams[this.currentGlobalTeam]
          if (!team)
            return console.error(`Cannot find a team by ID ${payload.teamD}`)

          const totalScore = this.getInt(team.totalScore)
          const score = this.getInt(payload.score)
          const userScore = this.getInt(this.userScore(payload.userID))

          const teamScore = totalScore + score
          const theScore = payload.userID ? userScore : score

          const totalScorePayload = {
            userID: payload.userID,
            totalScore: theScore,
            teamMission: this.teamMission,
            teamID: payload.teamID,
            teamScore: teamScore
          }

          // console.log("totalScorePayload", totalScorePayload)
          // GOT RID OF THIS AS WE DON"T TRACK ANYMORE
          obj = {}
          obj.totalScore = userScore + score
          obj.userID = payload.userID
          store.dispatch("setScore", obj)
          await store.dispatch("setTeamScore", totalScorePayload)
        }

        const promises = []

        if (behavior === "Draw: Pictionary" && payload.result) {
          const int = parseInt(this.currentMission.points)
          const pointsInt = isNaN(int) ? 10 : int
          if (this.nOfAllCorrectMissionPlays < 2) {
            payload.score = pointsInt
            console.log(`Giving ${payload.score} points to the drawing team`)
            // anonymous payload
            // if (!this.imageUrl) throw new Error("Got no image URL to submit")
            const answers = payload.answer.slice(payload.answer.length - 1)
            const [answer] = answers
            const drawingTeamPayload = {
              ...payload,
              // must be just one correct ansewer (to compute corret/wrong right)
              // the corret answer is always the last in the array
              answer: answers,
              // correct: { image: this.imageUrl, caption: answer },
              teamID: this.assignedTeam,
              userID: 0,
              // the ID must be unique, let get it from firebase
              id: null
            }
            promises.push(store.dispatch("addPlay", payload))
            promises.push(store.dispatch("addPlay", drawingTeamPayload))
            promises.push(updateTeamScore(drawingTeamPayload))
            promises.push(updateTeamScore(payload))
          } else {
            // substract the drawing team correct play as well as
            // the the fist submited teams play
            const n = this.nOfAllCorrectMissionPlays - 1
            console.log("the multiplier is", n)
            const quarter = (pointsInt / 100) * 25
            const reducedScore = pointsInt - quarter * n
            payload.score = reducedScore > 0 ? reducedScore : quarter
            promises.push(store.dispatch("addPlay", payload))
            promises.push(updateTeamScore(payload))
          }
        } else {
          if (behavior === "Draw: Each Team") {
            if (!this.submitted) {
              this.submitted = true
              promises.push(store.dispatch("addPlay", payload))
            }
          } else {
            promises.push(store.dispatch("addPlay", payload))
          }
          if (payload.result) promises.push(updateTeamScore(payload))
        }

        await Promise.all(promises)
      } catch (e) {
        console.error(e)
      } finally {
        Object.assign(this.$data, initialState())
      }
    }
  }
}
